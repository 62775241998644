import { Injectable } from '@angular/core';
import { NativeDateAdapter, MatDateFormats } from '@angular/material/core';

@Injectable()
export class CustomCalendarValidator extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): string {
    let day = this._to2digit(date.getDate());
    let month = this._to2digit(date.getMonth() + 1);
    let year = date.getFullYear();
    return day + '/' + month + '/' + year;
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'dd/MM/YYYY',
  },
  display: {
    dateInput: 'dd/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
